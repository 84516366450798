export const isUrlValid = (url: string, allowNoProtocol = false): boolean => {
  try {
    const urlObj = new URL(url);
    return (urlObj.protocol === 'http:' || urlObj.protocol === 'https:') && urlObj.host.indexOf('.') > -1;
  } catch {
    if (allowNoProtocol) {
      return isUrlValid('http://' + url, false);
    }

    return false;
  }
};
